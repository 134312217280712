<template>
  <div>
    <b-tabs v-model="tabIndex" content-class="mt-3 tab-autenticacao">
      <b-tab class="tabs-autenticao" title="Operação" 
        :title-link-class="linkClass(0)">

        <RelatorioOperacao />
      </b-tab>

      <b-tab class="tabs-autenticao" title="Abertos/Liquidados" 
        :title-link-class="linkClass(1)">

        <RelatorioAbertoLiquidado />
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
  import { DEFINIR_BREADCRUMB } from '@/store/breadcrumbs.module';
  import RelatorioOperacao from './RelatorioOperacao.vue';
  import RelatorioAbertoLiquidado from './RelatorioAbertoLiquidado.vue';

  export default {
    name: "RelatorioCedenteTabs",
    components: {
      RelatorioOperacao,
      RelatorioAbertoLiquidado
    },
    data() {
      return {
        tabIndex: 0,
      };
    },
    mounted() {
      this.$store.dispatch(DEFINIR_BREADCRUMB, [{ titulo: 'Relatórios' }]);
    },
    methods: {
      linkClass(idx) {
        if (this.tabIndex === idx) {
          return ["tabs-autenticacao", "active-tab"];
        } 
        else {
          return ["tabs-autenticacao", "inactive-tab"];
        }
      },
    },
  }
</script>

<style lang="scss">
  .nav-link {
    padding: 0.8rem 3rem;
    margin-right: 0.5rem;
  }

  .tab-autenticacao {
    min-height: 500px;
  }

  .active-tab {
    background-color: #a08c6a !important;
    color: white !important;
  }

  .inactive-tab {
    background-color: white !important;
  }
</style>
