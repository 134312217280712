<template>
  <div>
    <b-card class="mb-3">
      <div class="row">
        <input-date
          class="col-12 col-md-3 mb-1"
          v-model="filtro.dataDe"
          :label="$t('RELATORIO_CEDENTE.ABERTOS_LIQUIDADOS.DATA_DE')"
        />
        <input-date
          class="col-12 col-md-3 mb-1"
          v-model="filtro.dataAte"
          :label="$t('RELATORIO_CEDENTE.ABERTOS_LIQUIDADOS.DATA_ATE')"
        />

        <div class="col-12 col-md-6 p-md-label text-left">
          <b-button variant="secondary" @click="limparFiltro">
            {{ $t("GERAL.LIMPAR") }}
          </b-button>
        </div>
      </div>
    </b-card>

    <valorem-tabela
      :responsiva="false"
      :metodo-validacao-selecionar="metodoValidacaoSelecionar"
      :colunas="tabela.colunas"
      :quantidade-itens="tabela.quantidadeItens"
      v-model="tabela.dados"
      ref="tabela"
      em-card
      sem-selecionar-todos
      backend-paginado
      semPaginacao
      @paginar="listar"
    >
      <template #cell(abertos)="dados">
        <div class="centralizar-texto">
          <b-button
            @click="baixarRelatorioPDF(relatorios[relatorioAbertosIndex].value, dados.item)"
            variant="outline-secondary"
            class="ml-2"
            v-b-tooltip.hover
            title="Baixar PDF"
          >
            <div>
              <i class="fas fa-file-pdf"></i>
            </div>
          </b-button>
        </div>
      </template>

      <template #cell(liquidados)="dados">
        <div class="centralizar-texto">
          <b-button
            @click="baixarRelatorioPDF(relatorios[relatorioLiquidadosIndex].value, dados.item)"
            variant="outline-secondary"
            class="ml-2"
            v-b-tooltip.hover
            title="Baixar PDF"
          >
            <div>
              <i class="fas fa-file-pdf"></i>
            </div>
          </b-button>
        </div>
      </template>
    </valorem-tabela>
  </div>
</template>

<script>
import ContaBancariaService from "@/common/services/conta-bancaria/conta-bancaria.service";
import RelatorioCedenteService from "@/common/services/relatorio_cedente/relatorio_cedente.service";
import { InputDate } from "@/components/inputs";
import { START_LOADING, STOP_LOADING } from "@/store/Store";

import helpers from "@/common/utils/helpers";
import moment from "moment";
import Toast from "vue-toastification";
import Vue from "vue";
const options = {};
Vue.use(Toast, options);

export default {
  name: "RelatorioAbertoLiquidado",
  components: {
    InputDate,
  },
  data() {
    return {
      relatorios: helpers.TipoRelatorioCedenteAbertoLiquidado,
      relatorioAbertosIndex: 0,
      relatorioLiquidadosIndex: 1,
      filtro: {
        dataDe: null,
        dataAte: null,
      },
      tabela: {
        quantidadeItens: 0,
        paginaAtual: 0,
        porPagina: 10,
        dados: [],
        colunas: [
          {
            key: "nome",
            label: this.$t("RELATORIO_CEDENTE.ABERTOS_LIQUIDADOS.EMPRESA"),
          },
          { 
            key: "abertos", 
            label: this.$t("RELATORIO_CEDENTE.ABERTOS_LIQUIDADOS.RELATORIO_ABERTOS"), 
            thStyle: { width: "35%", "text-align":"center" },
          },
          { 
            key: "liquidados", 
            label: this.$t("RELATORIO_CEDENTE.ABERTOS_LIQUIDADOS.RELATORIO_LIQUIDADOS"), 
            thStyle: { width: "35%", "text-align":"center" },
          },
        ],
      },
    };
  },
  mounted() {
    console.log(this.relatorios);
    this.listar();
  },
  methods: {
    limparFiltro: function () {
      this.filtro = {
        dataDe: null,
        dataAte: null,
      };
    },
    listar: function (paginaAtual = 0, porPagina = 10) {
      this.$store.dispatch(START_LOADING);
      ContaBancariaService.listar(this.filtro, paginaAtual, porPagina)
        .then((res) => {
          this.tabela.dados = res.data.data.itens;
          this.paginaAtual = paginaAtual;
          this.porPagina = porPagina;
          this.tabela.quantidadeItens =
            res.data.data.paginacao.totalDeElementos;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    metodoValidacaoSelecionar: function () {
      return true;
    },
    baixarRelatorioPDF: function (relatorioValue, item) {
      if (!this.validarFiltro())
        return;

      this.$store.dispatch(START_LOADING);

      let form = {
        tipo: relatorioValue,
        codigoEmpresa: item.codigoEmpresa,
        codigoFilial: item.codigoFilial,
        dataDe: this.filtro.dataDe,
        dataAte: this.filtro.dataAte,
      };

      RelatorioCedenteService.gerarRelatorio(form)
        .then((res) => {
          // console.log(res);

          const blob = new Blob([res.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${relatorioValue}.pdf`;
          link.click();
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    validarFiltro: function () {
      if (this.filtro.dataDe == null || this.filtro.dataAte == null) {
        Vue.$toast.error(
          "É necessário preencher as datas para emissão do relatório"
        );
        return false;
      }

      if (this.filtro.dataDe || this.filtro.dataAte) {
        let dataInicial = moment(this.filtro.dataDe);
        let dataFinal = moment(this.filtro.dataAte);

        if (dataFinal < dataInicial) {
          Vue.$toast.error("Data final não pode ser maior que a inicial");
          return false;
        }
      }

      return true;
    },
  },
};
</script>

<style scoped>
.centralizar-texto {
  text-align: center;
}
</style>