<template>
  <div>
    <b-card class="mb-3">
      <div class="row">
        <input-date
          class="col-12 col-md-3 mb-1"
          label="Data operação"
          v-model="filtro.dataOperacao"
        />
        <div class="col-12 col-md-9 p-md-label text-left">
          <b-button variant="secondary" @click="limparFiltro">
            {{ $t("GERAL.LIMPAR") }}
          </b-button>
          <b-button variant="primary" @click="filtrar" class="ml-2">
            {{ $t("GERAL.PESQUISAR") }}
          </b-button>
        </div>
      </div>
    </b-card>

    <valorem-tabela
      :responsiva="false"
      em-card
      sem-selecionar-todos
      :metodo-validacao-selecionar="metodoValidacaoSelecionar"
      v-model="tabela.dados"
      :colunas="tabela.colunas"
      :quantidade-itens="tabela.quantidadeItens"
      ref="tabela"
      backend-paginado
      @paginar="listar"
    >
      <template #cell(acoes)="dados">
        <div>
          <b-dropdown text="Emitir relatório">
            <b-dropdown-item
              v-for="(relatorio, i) in relatorios"
              :key="`btn-${i}`"
              @click="baixarRelatorioPDF(relatorio.value, dados.item)"
              >{{ relatorio.text }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </template>
    </valorem-tabela>
  </div>
</template>
  
<script>
import OperacoesServices from "@/common/services/operacoes/operacoes.service";
import RelatorioCedenteService from "@/common/services/relatorio_cedente/relatorio_cedente.service";
import { InputDate } from "@/components/inputs";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import helpers from "@/common/utils/helpers";
import moment from "moment";
export default {
  name: "RelatorioOperacao",
  components: {
    InputDate,
  },
  data() {
    return {
      relatorios: helpers.TipoRelatorioCedenteOperacoes,
      filtro: {
        dataOperacao: null,
      },
      tabela: {
        quantidadeItens: 0,
        paginaAtual: 0,
        porPagina: 0,
        dados: [],
        colunas: [
          { key: "nomeFantasia", label: this.$t("RELATORIO_CEDENTE.OPERACOES.EMPRESA") },
          { key: "numeroBordero", label: this.$t("RELATORIO_CEDENTE.OPERACOES.BORDERO") },
          { key: "numeroAditivo", label: this.$t("RELATORIO_CEDENTE.OPERACOES.ADITIVO") },
          { key: "sequencialAditivo", label: this.$t("RELATORIO_CEDENTE.OPERACOES.SEQ_ADITIVO") },
          { key: "statusOperacao", label: this.$t("RELATORIO_CEDENTE.OPERACOES.SITUACAO") },
          {
            key: "valorBruto",
            label: this.$t("RELATORIO_CEDENTE.OPERACOES.VALOR_BRUTO"),
            formatter: (v) => {
              if (v) return helpers.formatarValor(v);
            },
          },
          {
            key: "valorLiquido",
            label: this.$t("RELATORIO_CEDENTE.OPERACOES.VALOR_LIQUIDO"),
            formatter: (v) => {
              if (v) return helpers.formatarValor(v);
            },
          },
          { key: "acoes", label: "" },
        ],

      }
    };
  },
  mounted() {
    this.filtro.dataOperacao = moment().format("yyyy-MM-DD");
    //this.listar();
  },
  methods: {
    filtrar: function () {
      this.$refs.tabela.limpar();
      this.listar();
    },
    limparFiltro: function () {
      this.filtro = {
        dataOperacao: null,
      };
    },
    listar: function (paginaAtual = 0, porPagina = 10) {
      this.$store.dispatch(START_LOADING);
      OperacoesServices.listarOperacoesAditivo(
        this.filtro,
        paginaAtual,
        porPagina
      )
        .then((res) => {
          this.tabela.dados = res.data.data.itens;
          this.paginaAtual = paginaAtual;
          this.porPagina = porPagina;
          this.tabela.quantidadeItens =
            res.data.data.paginacao.totalDeElementos;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    metodoValidacaoSelecionar: function () {
      return true;
    },
    baixarRelatorioPDF: function (relatorioValue, item) {
      this.$store.dispatch(START_LOADING);

      let form = {
        tipo: relatorioValue,
        codigoEmpresa: item.codigoEmpresa,
        codigoFilial: item.codigoFilial,
        sequencialAditivo: item.sequencialAditivo,
      };

      RelatorioCedenteService.gerarRelatorio(form)
        .then((res) => {
          // console.log(res);

          const blob = new Blob([res.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Operacao.pdf`;
          link.click();
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
<style lang="scss">
</style>
  