import ApiService from "@/common/api/api.service";
import helper from "@/common/utils/helpers.js";

const OperacoesService = {
  formatarBusca(retorno) {
    let retornoFormatado = [];
    let valorRetornado = [];

    valorRetornado.push(retorno.data.data);

    for (let i = 0; i < valorRetornado.length; i++) {
      let valorAlterado = helper.clonarObjeto(valorRetornado[i]);

      delete valorAlterado.id;
      valorAlterado.ddd = valorAlterado.telefoneSacado.ddd.ddd;
      valorAlterado.telefoneSacado.numero.telefoneSacado =
        (valorAlterado.telefoneSacado.ddd.ddd || "") +
        "" +
        (valorAlterado.telefoneSacado.numero.numero || "");
      valorAlterado.telefoneSacado.telefoneSacado =
        valorAlterado.telefoneSacado.numero;
      delete valorAlterado.telefoneSacado.numero;
      // delete valorAlterado.telefoneSacado.ddd;

      valorAlterado.enderecoSacado.numero.numeroEndereco =
        valorAlterado.enderecoSacado.numero.numero;
      valorAlterado.enderecoSacado.numeroEndereco =
        valorAlterado.enderecoSacado.numero;
      delete valorAlterado.enderecoSacado.numero;

      valorAlterado.existing = true;
      retornoFormatado.push({
        ...valorAlterado,
        ...valorAlterado,
        ...valorAlterado.enderecoSacado,
        ...valorAlterado.telefoneSacado,
      });
    }

    return retornoFormatado;
  },

  listarOperacoesRemessa: function (filtro, paginacao = 0, porPagina = 0) {
    let link = `operacao/remessa?NumeroDaPagina=${paginacao}&TamanhoDaPagina=${porPagina}`;
    link = link.concat(`${filtro.data ? `&DataDe=${filtro.data}` : ''}`);
    link = link.concat(`${filtro.data ? `&DataAte=${filtro.data}` : ''}`);
    return ApiService.get(link);
  },

  listarOperacoesAditivo: function (filtro, paginacao = 0, porPagina = 0) {
    let link = "operacao/aditivo";
    link += `${filtro.codigoUsuarioAgente ? '-comercial' : ''}`;
    link += `?NumeroDaPagina=${paginacao}&TamanhoDaPagina=${porPagina}`;
    link += `${filtro.dataOperacao ? `&DataOperacaoTexto=${filtro.dataOperacao}` : ''}`;
    link += `${filtro.codigoUsuarioAgente ? `&CodigoUsuarioAgenteTexto=${filtro.codigoUsuarioAgente}` : ''}`;
    return ApiService.get(link);
  },

  async buscarDadosPorCNPJ(cnpj) {
    let retorno = await ApiService.get(`operacao/dados-sacado/?Cnpj=${cnpj}`);

    let valorRetornado = this.formatarBusca(retorno);

    return valorRetornado;
  },

  gerarAcesso(form) {
    return ApiService.post(`/AcessoSacado`, { ...form });
  },

  reenviarCancelado(form) {
    return ApiService.post(`operacao/reenviar`, {
      ...form,
    });
  },
};

export default OperacoesService;
