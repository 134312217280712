import ApiService from "@/common/api/api.service";
import helpers from "../../../common/utils/helpers";

const RelatorioCedenteService = {
  gerarRelatorio : function(form){
    let params = helpers.serialize(form);
    return ApiService.downloadFile(`RelatorioCedente` + params);
  }
};

export default RelatorioCedenteService;
