import ApiService from "@/common/api/api.service";

const ContaBancariaService = {
  objectToQueryString(obj) {
    var str = [];
    for (var p in obj)
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));

    return str.join("&");
  },
  listar(form) {
    let sendForm = this.objectToQueryString(form);
    return ApiService.get(`ContaBancaria?${sendForm}`);
  },
};

export default ContaBancariaService;